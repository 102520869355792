import { Dispatch, memo, useCallback } from 'react';
import { SubtasksTags } from 'components/SubtasksTags';
import { TaskActions } from 'components/TaskActions';
import { TaskShortInfo } from 'components/TaskShortInfo';
import CollapseButton from 'components/common/CollapseButton/CollapseButton';
import {
  Heading,
  HeadingBottom,
  HeadingRightInner,
  HeadingTop,
  Title,
} from './styles';
import { IProjectTaskDto } from 'types';

interface IHeaderBlockProps {
  item?: IProjectTaskDto;
  index: number;
  projectId?: string;
  color?: string;
  setIsOpenEdit: (index: number) => void;
  isOpenInfo?: boolean;
  handleOpenInfo?: (isShow: boolean) => void;
  openNotify: ({
    isOpen,
    id,
    item,
  }: {
    isOpen: boolean;
    id: string;
    item: IProjectTaskDto;
  }) => void;
  toggleParent?: (visible: boolean) => void;
  invoice?: string;
  notAvailableProject?: boolean;
  dragRef: Element;
  isProjectViewTaskMode?: boolean;
  tasksCount: number;
  isProjectEditing?: boolean;
  setEditData: Dispatch<any>;
  toggleItemTemplateModal: () => void;
}

const HeaderBlock = ({
  item,
  index,
  setIsOpenEdit,
  isOpenInfo,
  handleOpenInfo,
  openNotify,
  notAvailableProject,
  projectId,
  color,
  toggleParent,
  invoice,
  isProjectViewTaskMode,
  tasksCount,
  isProjectEditing,
  setEditData,
  toggleItemTemplateModal,
}: IHeaderBlockProps) => {
  const { cost, price, name, order, sku, quantity } = item;

  const handleHeadingClick = useCallback(
    () => handleOpenInfo(!isOpenInfo),
    [handleOpenInfo, isOpenInfo]
  );

  const title = isProjectViewTaskMode
    ? item.name
    : `#${item.order} ${item.name}`;

  return (
    <Heading
      notAvailableProject={notAvailableProject}
      isOpen={isOpenInfo}
      onClick={handleHeadingClick}
    >
      <HeadingTop>
        <Title isOpenInfo={isOpenInfo}>{title}</Title>
        {!notAvailableProject && (
          <HeadingRightInner>
            {isOpenInfo && !notAvailableProject ? (
              <TaskActions
                tasksCount={tasksCount}
                openNotify={openNotify}
                setIsOpenEdit={setIsOpenEdit}
                index={index}
                item={item}
                isProjectEditing={isProjectEditing}
                setEditData={setEditData}
                toggleItemTemplateModal={toggleItemTemplateModal}
              />
            ) : (
              <TaskShortInfo
                isOpenInfo={isOpenInfo}
                sku={sku}
                cost={cost}
                price={price}
                quantity={quantity}
              />
            )}
          </HeadingRightInner>
        )}

        <CollapseButton isOpen={isOpenInfo} />
      </HeadingTop>
      <HeadingBottom>
        {!isOpenInfo && (
          <SubtasksTags
            projectId={projectId}
            color={color}
            notAvailableProject={notAvailableProject}
            subtasks={item.subtasks}
            toggleParent={toggleParent}
            invoice={invoice}
            taskName={name}
            taskOrder={order}
          />
        )}
      </HeadingBottom>
    </Heading>
  );
};

export default memo(HeaderBlock);
