import { memo, useCallback, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
// redux
import { autocompletePromiseCreator } from 'store/admin';
import { companySelector } from 'store/company';
import { prepareChecklist } from 'utils/helpers';
// components
import {
  Autocomplete,
  Checkbox,
  MobileMarginTopWrap,
  NumberInput,
  TextArea,
} from 'components/common';
// utils
import { useActionsRoutines, useAutocomplete } from 'hooks';
// styles
import { MainInfo, MainInfoItem } from './styles';
import { HookFormRecord, ISubtasksErrors, ITaskDto, TaskTypeEnum } from 'types';

interface ITaskInfoProps {
  item?: ITaskDto & { isRelated?: boolean; quantity?: number };
  itemWatch?: ITaskDto;
  name?: string;
  subtasks?: any;
  errors?: ISubtasksErrors;
  appendTask?: (task: ITaskDto) => void;
  order?: number;
  setRelated?: (tasks: ITaskDto[]) => void;
  appendContainer?: (container: ITaskDto) => void;
  isTemplate?: boolean;
}

const TaskEditInfo = ({
  subtasks,
  item,
  isTemplate,
  itemWatch,
  name = '',
  errors,
  appendTask,
  order,
  setRelated,
  appendContainer,
}: ITaskInfoProps) => {
  const { control, setValue, trigger } = useFormContext();
  const [isRelatedTasksExist, setIsRelatedTasksExist] = useState(false);
  const autocomplete = useActionsRoutines(autocompletePromiseCreator);
  const company = useSelector(companySelector, isEqual);
  const [onSearchName, optionsName, setOptionsName, onChangeNameField] =
    useAutocomplete(autocomplete, 'common', 'name');
  const [onSearchSKU, optionsSKU, setOptionsSKU, onChangeSKUField] =
    useAutocomplete(autocomplete, 'common', 'sku');
  const isNewTask = useMemo(
    () => !itemWatch?.type && !item?.type,
    [itemWatch, item]
  );

  const isAllowedAddNew = useMemo(
    () => (isNewTask || !isRelatedTasksExist) && !item?.isRelated,
    [isNewTask, isRelatedTasksExist, item?.isRelated]
  );

  const onChange = useCallback(
    async option => {
      setValue(`${name}sku` as HookFormRecord, option.sku);
      setValue(`${name}name` as HookFormRecord, option.name);
      setValue(`${name}cost` as HookFormRecord, option.cost);
      setValue(`${name}price` as HookFormRecord, option.price);
      setValue(`${name}description` as HookFormRecord, option.description);
      setValue(`${name}type` as HookFormRecord, option.type);
      setValue(`${name}_id` as HookFormRecord, item?._id);
      setValue(`${name}isQuantity` as HookFormRecord, option.isQuantity);
      setValue(`${name}createdAt` as HookFormRecord, undefined);
      setValue(`${name}updatedAt` as HookFormRecord, undefined);

      const firstTask = {
        sku: option.sku,
        name: option.name,
        cost: option.cost,
        price: option.price,
        description: option.description,
        type: option.type,
        _id: item?._id,
        createdAt: option.createdAt,
        updatedAt: option.updatedAt,
        subtasks: [],
      };

      const newSubtasks = option?.subtasks?.map(item => ({
        name: item.name,
        plannedHours: item.plannedHours,
        templateHours: item.templateHours || 0,
        actualHours: item.actualHours || 0,
        order: item.order,
        isApproveRequired: item.isApproveRequired,
        isPhotoRequired: item.isPhotoRequired,
        subtaskTypeId: item.subtaskTypeId,
        notes: item.notes,
        files: item.files,
        checklist: prepareChecklist(item?.checklist || []),
      }));
      firstTask.subtasks = newSubtasks;
      subtasks.replace(newSubtasks);

      if (option.related) {
        if (!!option.related.length) setIsRelatedTasksExist(true);
        const tasksList = option.related.map((task, i) => ({
          ...task,
          isRelated: true,
          subtasks: task?.subtasks?.map(item => ({
            name: item.name,
            plannedHours: item.plannedHours,
            templateHours: item.templateHours || 0,
            actualHours: item.actualHours || 0,
            order: item.order,
            isApproveRequired: item.isApproveRequired,
            isPhotoRequired: item.isPhotoRequired,
            subtaskTypeId: item.subtaskTypeId,
            notes: item.notes,
            files: item.files,
            checklist: prepareChecklist(item?.checklist || []),
          })),
          order: +order + +i,
        }));

        const tasks = tasksList.filter(
          task =>
            task.type === TaskTypeEnum.Common || Object.keys(task).length !== 0
        );

        const container = option.related.find(
          task => task.type === TaskTypeEnum.Container
        );

        if (appendContainer && container) appendContainer(container);
        if (isAllowedAddNew && tasks?.length) {
          if (appendTask) {
            const commonTasks = tasks.reduce((acc, task) => {
              if (task.type === TaskTypeEnum.Common) {
                acc.push({ ...task, _id: undefined });
              }

              return acc;
            }, []);

            appendTask(commonTasks);
          } else if (setRelated) {
            setRelated([firstTask, ...tasks]);
          }
        }
      } else {
        setIsRelatedTasksExist(false);
      }

      await trigger();
    },
    [
      trigger,
      subtasks,
      item,
      setValue,
      name,
      order,
      appendTask,
      setRelated,
      appendContainer,
      isAllowedAddNew,
    ]
  );

  const onNameChange = useCallback(
    (value, option) =>
      onChangeNameField(value, option, onChange, setOptionsSKU),
    [onChange, setOptionsSKU, onChangeNameField]
  );

  const onSKUChange = useCallback(
    (value, option) => {
      const val = value.slice(0, 30).replace(/[^a-zA-Z0-9-]/g, '');
      return onChangeSKUField(val, option, onChange, setOptionsName);
    },
    [onChange, setOptionsName, onChangeSKUField]
  );

  const handleParsePrice = useCallback(value => {
    const res = value.match(/[0-9]+\.?[0-9]{0,2}/);
    return res && res[0];
  }, []);

  const handleChangeValue = useCallback(value => value || 0, []);
  const formatPrice = useCallback(value => value && `$ ${value}`, []);
  const handleIsQuantity = useCallback(value => !value.target.value, []);

  return (
    <MainInfo>
      <MainInfoItem>
        <Controller
          render={({ field }) => (
            <Autocomplete
              value={field.value}
              name={field.name}
              dropdownClassName="autocomplete"
              label="Title"
              isRequired
              className="TaskTitleInput"
              width="284px"
              height="30px"
              placeholder="Item name"
              error={errors?.name?.message}
              options={isNewTask || !isRelatedTasksExist ? optionsName : null}
              onSearch={isNewTask || !isRelatedTasksExist ? onSearchName : null}
              onChange={(value, option) =>
                field.onChange(onNameChange(value, option))
              }
            />
          )}
          name={`${name}name` as HookFormRecord}
          defaultValue={item?.name}
          control={control}
        />
        <MobileMarginTopWrap>
          <Controller
            render={({ field }) => (
              <Autocomplete
                value={field.value}
                name={field.name}
                dropdownClassName="autocomplete"
                label="SKU"
                width="111px"
                isRequired={isTemplate || company?.isSkuRequired}
                selectwidth="unset"
                height="30px"
                placeholder="SKU here"
                error={errors?.sku?.message}
                options={isAllowedAddNew ? optionsSKU : null}
                onSearch={isAllowedAddNew ? onSearchSKU : null}
                onChange={(value, option) =>
                  field.onChange(onSKUChange(value, option))
                }
              />
            )}
            name={`${name}sku` as HookFormRecord}
            defaultValue={item?.sku}
            control={control}
          />
        </MobileMarginTopWrap>
        <MobileMarginTopWrap>
          <Controller
            render={({ field }) => (
              <NumberInput
                value={field.value}
                name={field.name}
                label="Cost"
                width="111px"
                height="30px"
                placeholder="$0"
                error={errors?.cost?.message}
                noicon="true"
                isPriceInput
                formatter={formatPrice}
                onChange={value => field.onChange(handleChangeValue(value))}
                parser={handleParsePrice}
              />
            )}
            name={`${name}cost` as HookFormRecord}
            defaultValue={item?.cost || 0}
            control={control}
          />
        </MobileMarginTopWrap>
        <MobileMarginTopWrap>
          <Controller
            render={({ field }) => (
              <NumberInput
                value={field.value}
                name={field.name}
                label="Sale Price"
                width="111px"
                height="30px"
                placeholder="$0"
                error={errors?.price?.message}
                noicon="true"
                isPriceInput
                formatter={formatPrice}
                onChange={value => field.onChange(handleChangeValue(value))}
                parser={handleParsePrice}
              />
            )}
            name={`${name}price` as HookFormRecord}
            defaultValue={item?.price || 0}
            control={control}
          />
        </MobileMarginTopWrap>
      </MainInfoItem>
      {(itemWatch?.isQuantity || item?.quantity) && (
        <Controller
          render={({ field }) => (
            <NumberInput
              value={field.value}
              name={field.name}
              label="Quantity"
              width="111px"
              height="30px"
              placeholder="0"
              error={errors?.quantity?.message}
              noicon="true"
              onChange={value => field.onChange(handleChangeValue(value))}
            />
          )}
          name={`${name}quantity` as HookFormRecord}
          defaultValue={item?.quantity || 80}
          control={control}
        />
      )}
      {isTemplate && (
        <Controller
          render={({ field }) => (
            <Checkbox
              name={field.name}
              label="Display Quantity"
              value={field.value}
              onChange={value => {
                field.onChange(handleIsQuantity(value));
              }}
              checked={field.value}
            />
          )}
          name={`${name}isQuantity` as HookFormRecord}
          defaultValue={item?.isQuantity || false}
          control={control}
        />
      )}
      <Controller
        render={({ field }) => (
          <TextArea
            onChange={field.onChange}
            value={field.value}
            name={field.name}
            label="Description"
            placeholder="Write description here"
            error={errors?.description?.message}
            rows={3}
          />
        )}
        name={`${name}description` as HookFormRecord}
        control={control}
        defaultValue={item?.description}
      />
    </MainInfo>
  );
};

export default memo(TaskEditInfo, isEqual);
