import { memo, useCallback, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import isEqual from 'react-fast-compare';
import { Controller, UseFormSetValue, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  cloneFilesSelector,
  insertSubtaskDraftFilesBuffer,
  insertSubtaskFilesBuffer,
  insertSubtaskTemplateFilesBuffer,
  putSubtasksAttachmentsPromiseCreator,
} from 'store/dashboard';
import { isFilesLoadingSelector } from 'store/loading';
import { validateUploadFile } from 'utils/helpers';
import File from './File';
import { shouldDeleteFileFromStorage } from './utils/shouldDeleteFileFromStorage';
import { ChecklistForm } from 'components/ChecklistForm';
import { DuplicateButton, TextArea } from 'components/common';
import { useActions, useActionsRoutines } from 'hooks';
import { MAX_FILE_SIZE_MB, PROJECT_UPLOAD_FILE_TYPES } from 'lib/constants';
import { Link, Notes, PaperClip } from 'lib/icons';
import { ChecklistMenu } from 'lib/icons/ChecklistMenu';
import { Popover, Spin } from 'antd';
import theme from '../../styles/theme';
import {
  Action,
  ActionsButtons,
  ActionsWrap,
  Files,
  LoadingOutlinedStyled,
  Wrap,
} from './styles';
import { HookFormRecord, IAttachmentInfo, IChecklist, IProject } from 'types';

interface ISubtaskActionsProps {
  name?: string;
  subtaskId?: string;
  checklist?: IChecklist[];
  notesValue?: string | boolean;
  filesValue?: any[];
  setValue?: UseFormSetValue<string | any>;
  isInProject?: boolean;
  disableChecklist?: boolean;
  isTemplate?: boolean;
  control?: any;
  notesError?: string;
  projectId?: string;
  project?: IProject;
  isSubtaskTypeChecklist?: boolean;
  setParentFiles?: (newFiles: IAttachmentInfo[]) => void;
  updateChecklist?: (
    subtaskId: number,
    newChecklist: IChecklist
  ) => Promise<void> | void;
  setChecklist?: (checklist: IChecklist[]) => void;
  isEdit?: boolean;
  subtasksCount?: number;
  handleDuplicateSubtask?: (index: number, amount: number) => void;
  index?: number;
  isStandalone?: boolean;
  isRecurringTask?: boolean;
}

const SubtaskActions = ({
  isEdit,
  notesValue,
  filesValue,
  notesError,
  isInProject,
  projectId,
  setValue,
  control,
  setParentFiles,
  name = '',
  subtaskId,
  isTemplate,
  updateChecklist,
  checklist,
  isSubtaskTypeChecklist,
  project,
  setChecklist,
  disableChecklist,
  subtasksCount,
  handleDuplicateSubtask,
  index,
  isRecurringTask,
  isStandalone,
}: ISubtaskActionsProps) => {
  const { getValues } = useFormContext();
  const putAttachments = useActionsRoutines(
    putSubtasksAttachmentsPromiseCreator
  );

  const insertToDeletedFilesBuffer = useActions(insertSubtaskFilesBuffer);
  const insertToDraftFilesBuffer = useActions(insertSubtaskDraftFilesBuffer);
  const insertToSubtaskTemplateFilesBuffer = useActions(
    insertSubtaskTemplateFilesBuffer
  );
  const isFilesLoading = useSelector(isFilesLoadingSelector);
  const cloneFiles = useSelector(cloneFilesSelector, isEqual);
  const [showNotes, setShowNotes] = useState(notesValue);
  const [files, setFiles] = useState(filesValue);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecklistPopoverVisible, setChecklistPopoverVisible] =
    useState(false);
  const [isChecklistLoading, setIsChecklistLoading] = useState(false);
  const [newSubtaskChecklist, setNewSubtaskChecklist] = useState(
    checklist || []
  );

  useEffect(() => {
    if (!newSubtaskChecklist?.length) {
      setValue(`${name}checklist`, checklist);
      setNewSubtaskChecklist(checklist);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklist, name, setValue]);
  const deleteNotes = useCallback(() => {
    setValue(`${name}notes`, '');
    setShowNotes(false);
  }, [setValue, setShowNotes, name]);

  useEffect(() => {
    if (setParentFiles) {
      setParentFiles(files);
    }
  }, [files, setParentFiles]);

  const onSelectFile = useCallback(
    acceptedFiles => {
      const { allowedFiles } = validateUploadFile({
        files: acceptedFiles,
        allowedTypes: PROJECT_UPLOAD_FILE_TYPES,
        maxFileSize: MAX_FILE_SIZE_MB,
      });
      if (!allowedFiles.length) return;

      setIsLoading(true);
      const formData = new FormData();
      allowedFiles.forEach(file => formData.append('files', file));
      putAttachments(formData)
        .then(res => {
          const result = res.map((item, index) => ({
            ...item,
            originalName: allowedFiles[index].name,
          }));
          if (!subtaskId || isTemplate) {
            res.forEach(item => insertToDraftFilesBuffer(item.filePath));
          } else {
            insertToDraftFilesBuffer({
              id: subtaskId,
              paths: res.map(item =>
                typeof item === 'string' ? item : item.filePath
              ),
            });
          }

          const newFiles = [...files, ...result];
          setFiles(newFiles);
          if (setParentFiles) {
            setParentFiles(newFiles);
          }

          setValue(`${name}files`, newFiles);
        })
        .catch(err => console.error(err))
        .finally(() => setIsLoading(false));
    },
    [
      putAttachments,
      isTemplate,
      subtaskId,
      name,
      files,
      setValue,
      setParentFiles,
      insertToDraftFilesBuffer,
    ]
  );

  const filterFilesList = useCallback(
    filePath => {
      const newFiles = files.filter(item =>
        typeof item === 'string' ? item : item.filePath !== filePath
      );
      if (setParentFiles) setParentFiles(newFiles);

      setFiles(newFiles);
      setValue(`${name}files`, newFiles);
    },
    [files, name, setParentFiles, setValue]
  );

  const removeFile = useCallback(
    (filePath: string) => {
      const shouldDeleteFile =
        isStandalone || isRecurringTask || shouldDeleteFileFromStorage({
          filePath,
          cloneFiles,
          // in case of editing single task mode, form data is task
          tasks: getValues('tasks') ?? [getValues()],
        });

      if (shouldDeleteFile) {
        if (subtaskId && !isTemplate) {
          insertToDeletedFilesBuffer({ id: subtaskId, paths: [filePath] });
        } else {
          insertToSubtaskTemplateFilesBuffer({ paths: [filePath] });
        }
      }

      filterFilesList(filePath);
    },
    [
      filterFilesList,
      insertToDeletedFilesBuffer,
      insertToSubtaskTemplateFilesBuffer,
      isTemplate,
      subtaskId,
      cloneFiles,
      getValues,
      isRecurringTask,
      isStandalone,
    ]
  );

  useEffect(() => {
    if (project && setChecklist) {
      project?.tasks?.forEach(task =>
        task?.subtasks?.forEach(subtask => {
          if (subtask?._id === subtaskId) {
            setChecklist(subtask?.checklist);
          }
        })
      );
    }
  }, [project, subtaskId, setChecklist]);

  const handleShowNotes = useCallback(() => setShowNotes(true), [setShowNotes]);
  const handleUpdateCheckist = useCallback(
    async (subtaskId, newChecklist) => {
      setValue(`${name}checklist`, newChecklist);

      if (subtaskId && updateChecklist) {
        setIsChecklistLoading(true);
        await updateChecklist(subtaskId, newChecklist);
        setIsChecklistLoading(false);
        setNewSubtaskChecklist(newChecklist);
      } else {
        setNewSubtaskChecklist(newChecklist);
      }
    },
    [updateChecklist, name, setValue]
  );

  const handleCloseChecklistPopover = useCallback(
    () => setChecklistPopoverVisible(false),
    []
  );

  const checklistPopover = useCallback(
    () => (
      <ChecklistForm
        handleCloseChecklist={handleCloseChecklistPopover}
        isEdit={isEdit}
        onFinishEditing={handleUpdateCheckist}
        items={newSubtaskChecklist}
        subtaskId={subtaskId}
        disableCheckbox={disableChecklist}
        projectId={projectId}
        isSubtaskTypeChecklist={isSubtaskTypeChecklist}
      />
    ),
    [
      subtaskId,
      newSubtaskChecklist,
      projectId,
      isSubtaskTypeChecklist,
      disableChecklist,
      handleCloseChecklistPopover,
      handleUpdateCheckist,
      isEdit,
    ]
  );
  const handleGetPopupContainer = useCallback(triggerNode => triggerNode, []);

  const handleChecklistVisibleChange = useCallback(visibility => {
    setChecklistPopoverVisible(visibility);
  }, []);

  const handleDuplicate = useCallback(
    (amount: number) => {
      handleDuplicateSubtask?.(index, amount);
    },
    [index, handleDuplicateSubtask]
  );

  return (
    <Wrap>
      {showNotes && (
        <Controller
          render={({ field }) => (
            <TextArea
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              label="Notes"
              placeholder="Write your notes.."
              error={notesError}
              style={{ marginTop: 12, marginBottom: 15 }}
              rows={2}
              closeClick={deleteNotes}
            />
          )}
          name={`${name}notes` as HookFormRecord}
          control={control}
        />
      )}
      <ActionsWrap>
        <Files>
          {files?.map((file, i) => (
            <File key={i} file={file} removeFile={removeFile} />
          ))}
          <Spin
            indicator={<LoadingOutlinedStyled spin />}
            spinning={isLoading}
          />
        </Files>
        <ActionsButtons>
          {isInProject && (
            <>
              {!isTemplate && (
                <DuplicateButton
                  count={subtasksCount}
                  isEdit
                  onConfirm={handleDuplicate}
                />
              )}
              <Action style={{ cursor: 'auto' }} color={theme.colors.lightBlue}>
                <Link width="18" height="8" color={theme.colors.lightBlue} />
                <span>Public link</span>
              </Action>
            </>
          )}
          {!isChecklistLoading && (
            <Popover
              onVisibleChange={handleChecklistVisibleChange}
              getPopupContainer={handleGetPopupContainer}
              content={checklistPopover}
              visible={isChecklistPopoverVisible}
              destroyTooltipOnHide
              overlayClassName="MenuPopover"
              trigger="click"
              placement="left"
            >
              <Action>
                <ChecklistMenu />
                <span>Checklist</span>
              </Action>
            </Popover>
          )}
          {isChecklistLoading && (
            <LoadingOutlinedStyled
              spin
              style={{ fontSize: '14px', width: '21px' }}
            />
          )}
          {!showNotes && (
            <Action onClick={handleShowNotes}>
              <Notes />
              <span>Add note</span>
            </Action>
          )}
          <Dropzone onDrop={acceptedFiles => onSelectFile(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <Action
                {...getRootProps()}
                style={{ marginTop: 3 }}
                $disabled={isFilesLoading}
              >
                <input
                  disabled={isFilesLoading}
                  {...getInputProps()}
                  accept={PROJECT_UPLOAD_FILE_TYPES.join(',')}
                />
                <PaperClip width={'10'} height={'17'} />
                <span>Add file</span>
              </Action>
            )}
          </Dropzone>
        </ActionsButtons>
      </ActionsWrap>
    </Wrap>
  );
};

export default memo(SubtaskActions, isEqual);
